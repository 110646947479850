.navbar {
    box-shadow: 0 1px 15px gray;
    padding: 10px 80px;
}

.navbar img {
    width: 40px;
    border-radius: 50%;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-left, .nav-right, .nav-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.nav-left, .nav-right {
    gap: 2rem;
}


.nav-links {
    display: flex;
}

.nav-links li {
    list-style-type: none;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
}

.nav-links li:first-child:after {
    position: relative;
    top: 70%;
    content: "";
    width: 100%;
    height: 4px;
    background-color: orange;
}

.nav-links li a {
    text-decoration: none;
    color: #000;
    font-size: 20px;
    font-weight: bold;
    transition: all 0.3s;
}

.nav-links li a:hover {
    color: rgb(73, 241, 73);
}


.nav-search {
    background-color: #ebf1f6bc;
}

.nav-search-icon {
    position: relative;
    top: 4px;
    left: 12px;
}

.nav-search input {
    padding: 10px 15px;
    outline: 0;
    border: 0;
    background-color: #ebf1f6bc;
}

.nav-button button {
    padding: 6px 15px;
    color: #fff;
    background-color: #000000db;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.nav-button-icon {
    position: relative;
    top: 2px;
}

.nav-profile {
    cursor: pointer;
}

.nav-profile h3 {
    margin: 0 10px;
}


.mobile-logo {
    display: none;
}

.mobile-logo a {
    color: #06ab38;
    text-decoration: none;
    font-size: 25px;
    font-weight: bold;
}

.mobile-logo span {
    color: #0b1f6d;
}

.mobile-logo img {
    width: 22px;
    position: relative;
    bottom: 8px;
    right: 2px;

}


.icon {
    color: #000000f1;
    font-size: 30px;
    cursor: pointer;
    display: none;
}

.icon.menu-btn {
    position: relative;
    top: 3px;
    font-size: 35px;
}


@media only screen and (max-width:1250px) {
    .navbar {
        padding: 10px 20px;
    }

    .nav-left, .nav-right {
        gap: 1rem;
    }

    .nav-links li a {
        font-size: 15px;
    }

}

@media only screen and (max-width:990px) {

    .icon {
        display: block;
    }

    .mobile-logo {
        display: block;
    }

    .nav-logo {
        display: none;
    }

    .nav-links, .nav-search {
        display: none;
    }


}

@media only screen and (max-width:730px) {
    .nav-search {
        display: none;
    }
}


@media only screen and (max-width:500px) {
    .nav-right {
        display: none;
    }
}