.experiences-container {
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    width: 100%;
}

.experiences-top {
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
}

.experiences-top p{
    cursor: pointer;
}

.experiences-card-container {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    flex-wrap: wrap;
    
}

.experiences-card {
    background: #f8f8ff;
    width: 220px;
    border-radius: 8px;
    box-shadow: 0 1px 10px 1px #817f7f9c;
    margin: 10px 5px;
}

.card-img img {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.card-body {
    padding: 2px 8px;
    text-align: justify;
}

.card-title {
    font-weight: 600;
}

.card-dec {
    margin: 8px 0;
}

.card-by{
    color: gray;
    font-size: 14px;
}

.card-btn {
    margin: 10px 0;
    padding: 8px 3px;
    font-size: 16px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    transition: all 0.3s;
}

.card-btn:hover {
    color: rgb(31, 168, 4);
    font-weight: bold;
}

@media only screen and (max-width:865px){
    .experiences-card-container{
        padding: 10px 5px;
    }
    .experiences-container {
        width: auto;
    }
}

@media only screen and (max-width:500px){
    .experiences-card-container{
        padding: 0;
    }

    .mobile-hidden-card{
        display: none;
    }

    .experiences-card {
        background: #f8f8ff;
        width: 158px;
        border-radius: 8px;
        box-shadow: 0 1px 10px 1px #817f7f9c;
        margin: 7px 3px;
    }

    .card-body {
        padding: 2px 5px;
        text-align: left;
    }
    
    .card-title {
        font-size: 16px;
        font-weight: 600;
    }
    
    .card-dec {
        font-size: 14px;
    }
    
    .card-by{
        font-size: 12px;
    }
  
}
