.home-container{
    padding:2rem 3rem;
    background-color: #edebe8;
}

.home-top p{
    margin:10px 0px 30px 8px;
}

.home-dashboard{
    display:flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;
}

.home-left, .home-right{
    display:flex;
    flex-direction: column;
    gap: 2rem;
}


@media only screen and (max-width:865px){
    .home-container{
        padding:2rem 0;
    }

    .home-top{
        padding: 0 1rem;
    }

    .classes {
        display: none;
    }

    .stats {
        display: none;
    }

}