.classes-container {
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    width: 100%;
}

.classes-top {
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
}

.classes-top p{
    cursor: pointer;
}

.classes-card-container {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    flex-wrap: wrap;
}

.classes-card {
    background: #f8f8ff;
    width: 230px;
    /* margin: 0 10px; */
    border-radius: 8px;
    box-shadow: 0 1px 10px 1px #817f7f9c;
    margin: 10px 0;
}

.classes-card-img img {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.classes-card-body {
    padding: 2px 8px;
    text-align: justify;
}

.classes-card-title {
    font-weight: 600;
}

.classes-card-dec {
    margin: 8px 0;
}

.classes-card-by {
    color: gray;
    font-size: 14px;
}

.classes-card-btn {
    margin: 15px 0;
    font-size: 16px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    border: 0;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    transition: all 0.3s;
    background: #07a531;
    color: #fff;
    padding: 8px 16px;
}

.classes-card-btn:hover {
    background-color: #717109;
}