.mentors-container {
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
}

.mentors-box-container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.mentors-box {
    display: flex;
    margin:10px 0;
    width: auto;
}

.mentors-box-left {
    margin-right: 10px;
}

.mentors-box-left img {
    width: 120px;
    border-radius: 8px;
}

.mentors-box-right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.mentors-box-right-details h3 {
    margin-bottom: 5px;
}

.mentors-box-right-details p {
    color: gray;
    font-size: 14px;
}

.mentors-right-btn a {
    text-decoration: none;
    color: #6dce0c;
}

@media only screen and (max-width:865px){
    .mentors-box-container {
        flex-direction: row;
        flex-wrap: wrap;
    }

}

@media only screen and (max-width:500px){
    .mentors-box {
        width: 150px;
    }
    .mentors-box-left {
        margin-right: 5px;
    }
    
    .mentors-box-left img {
        width: 100%;
        border-radius: 8px;
    }
    
    .mentors-box-right-details h3 {
        margin-bottom: 5px;
        font-size: 12px;
    }
    
    .mentors-box-right-details p {
        font-size: 8px;
    }

    .mentors-right-btn a{
        display: none;
    }
  
}