.stats-container {
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
}

.stats-box-container {
    margin-top: 10px;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.stats-box {
    padding: 10px;
    background-color: #edebe8;
    border-radius: 5px;
    height: 100px;
    width: 120px;
    margin: 0 8px;
}

.stats-box h2 {
    padding: 5px;
}

.stats-box p {
    padding: 15px 10px;
}